import "./App.scss";
import logo from "./images/logo.png";

function App() {
  return (
    <div className="App overflow-h-hidden">
      <img src={logo} alt="logo" />
      <p className="text-white text-4xl sm:text-2xl text-center my-8 react">
        We are coming soon
      </p>
      <p className="text-white w-auto sm:px-12 text-center node">
        We are working very hard to add new features and improve the usability
        of our site. <br /> In the meantime, shoot us an email:{" "}
        <a className="font-semibold" href="mailto:hello@codevillage.ng">
          hello@codevillage.ng
        </a>
        .
      </p>
    </div>
  );
}

export default App;
